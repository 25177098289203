import React from 'react'

import Layout from '../components/layout'

import SEO from '../components/seo'

import LoginForm from '../components/login-form'

import Footer from '../components/footer'

import Header from '../components/header'

export default () => {
    return (
        <Layout>
            <SEO
                title={'Secure login'}
                description={`Verifeco is a technology company that builds software
                        products for the world. Businesses of every size—from
                        new startups to public companies—use our software to
                        exchange and manage their business card online. Since
                        being in beta the idea have changed how many corporates
                        and professionals think, use and issue business cards.`}
            />
            <Header mode="site-header" />
            <LoginForm />
            <Footer />
        </Layout>
    )
}
