import React, { useState, useEffect, useRef } from 'react'

import styled from 'styled-components'

import Input from './input'

import Button from './button'

import { Link as GatsbyLink } from 'gatsby'

import { Container as PageContainer } from './styles'

import { LOGIN } from './data-provider'

import { useMutation } from '@apollo/react-hooks'

import { isEmpty, get, values } from 'lodash-es'

const Container = styled(PageContainer)`
    min-height: 70vh;
`

const Title = styled.h1`
    margin: 0 0 1rem 0;
    font-size: 1.5rem;
    font-weight: 400;
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        margin-top: 5rem;
    }
`

const Link = styled(GatsbyLink)`
    color: ${props => props.theme.blue};
    text-decoration: none;
`

const Text = styled.p`
    font-size: 0.8rem;
    line-height: 140%;
`

const Form = styled.form``

export default () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false)

    const [user, setUser] = useState({
        email: '',
        password: '',
    })
    const [login, { data, loading }] = useMutation(LOGIN)

    const form = useRef({})

    const getError = path => {
        const type = get(data, 'login.type')
        const errorPath = get(data, 'login.errorPath')

        if (type === 'ERROR' && errorPath === path) {
            return data.login
        }
        return null
    }

    useEffect(() => {
        if (typeof window !== 'undefined' && window.localStorage.token) {
            setIsLoggedIn(true)
        }
    }, [])

    useEffect(() => {
        if (isLoggedIn) {
            if (typeof window !== 'undefined') {
                window.location = '/personal-area'
            }
        }
    }, [isLoggedIn])

    useEffect(() => {
        if (data && data.login && data.login.token) {
            localStorage.token = data.login.token
            if (typeof window !== 'undefined') {
                setTimeout(function() {
                    window.location = '/personal-area'
                }, 10)
            }
        }
    }, [data])

    console.log({ values: values(user).some(v => !isEmpty(v)) })

    return (
        <Container>
            <Form
                ref={form}
                onSubmit={e => {
                    e.preventDefault()
                    if (!loading) login({ variables: { user } })
                }}
            >
                <Title>Login</Title>
                <Input
                    label="email"
                    onChange={e => setUser({ ...user, email: e.target.value })}
                    error={getError('email')}
                    value={user.email}
                    disabled={loading}
                />
                <Input
                    label="password"
                    type="password"
                    disabled={loading}
                    value={user.password}
                    error={getError('password')}
                    onChange={e =>
                        setUser({ ...user, password: e.target.value })
                    }
                />
                <Button
                    loading={loading}
                    disabled={!values(user).every(v => !isEmpty(v))}
                >
                    {loading ? 'Submitting...' : 'Login'}
                </Button>
                <Text>
                    If you forgot your password{' '}
                    <Link to="/password-reset">click here.</Link>
                </Text>
                <Text>
                    If you don't have an account, go ahead and{' '}
                    <Link to="/create-business-card">
                        create your first business card.
                    </Link>
                </Text>
            </Form>
        </Container>
    )
}
